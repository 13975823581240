// import Home from "@/views/Home";
// import Otp from "@/views/Otp";
// import RenewPackage from "@/views/RenewPackage";
// import WeekendSubscription from "@/views/WeekendSubscription";
import { Home, Otp, RenewPackage, WeekendSubscription } from "@/views";
export const routeContents = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Newspaper page ",
      description: "Journal d’information et d’analyses économiques",
    },
  },
  {
    path: "/:code",
    name: "Code",
    component: Home,
    meta: {
      title: "Page with journal code",
      description: "Journal d’information et d’analyses économiques",
    },
  },
  {
    path: "/c/:mode",
    name: "HomeMode",
    component: Home,
    meta: {
      title: "Page du journal",
      description: "Journal d’information et d’analyses économiques",
    },
  },
  {
    path: "/otp/:id",
    name: "otp",
    component: Otp,
    meta: {
      title: "OTP Page",
      description: "Journal d’information et d’analyses économiques",
    },
  },
  {
    path: "/renew/:code",
    name: "RenewPackage",
    component: RenewPackage,
    meta: {
      title: "Subscription renewal",
      description: "Journal d’information et d’analyses économiques",
    },
  },
  {
    path: "/weekend/:phone?",
    name: "WeekendSubscription",
    component: WeekendSubscription,
    meta: {
      title: "News of the week",
      description: "Journal d’information et d’analyses économiques",
    },
  },
  {
    path: "/weekend/:phone?",
    name: "WeekendSubscription",
    component: WeekendSubscription,
    meta: {
      title: "News of the week",
      description: "Journal d’information et d’analyses économiques",
    },
  },
];
