export * from "./homePageContents";
export *  from "./routeContents";
export  const appName = "Cameroon Business Today";




// <li
// v-for="(subscription, key) in subscriptions"
// :key="subscription.name"
// :value="subscription.id"
// as="template"
// :style="{
//   backgroundColor: subscription.id === selected?.id ? '#FCFBE0' : ' ',
//   background:
//     subscription.is_best && subscription.id !== selected?.id ? '#FCFBE0' : ' ',
//   border: subscription.is_best ? '1px solid #FCFBE0' : ' ',
// }"
// :class="[
//   { ' bg-opacity-75 text-gray-900': subscription.id === selected?.id },
//   {
//     'text-gray-900 hover:bg-gray-300 hover:bg-opacity-10':
//       subscription.id !== selected.id,
//   },
//   { 'border-t border-gray-400': key > 0 },
//   'relative cursor-default select-none py-1 pl-2 pr-1 flex justify-between items-center'
// ]"
// class="cursor-pointer text-left relative"
// @click="handleChangeSubcription(subscription)"
// >
// <div>
//   <span class="font-medium block">
//     <span
//       v-if="subscription.promo"
//       style="font-size: 14px"
//       v-html="subscription.promo_text_style"
//     ></span>
//     <template v-else>{{ subscription.name }}</template>
//     ({{ subscription?.price }} XAF)</span
//   >
//   <span style="font-size: 12px">{{ subscription.pu }} XAF / newspaper</span>
  
// </div>
// <div
//   class="bg-green flex justify-center items-center rounded-md py-1 px-1 h-12 gap-x-1 w-20"
//   v-if="subscription.promo"
// >
//   <div class="text-center flex flex-col">
//     <span style="color: #f0cc81" class="block text-md font-extrabold">{{
//       subscription.nn + subscription.promo
//     }}</span>
//     <span class="text-white -mt-2" style="font-size: 10px">Jours</span>
//   </div>
//   <GiftComponent />
// </div>
// <BestBadgeComponent v-else-if="subscription.is_best" />
// </li>
